<template>
  <form @submit.prevent="onSubmit">
    <div v-if="error" class="py-3 px-5 mb-3 rounded bg-red-50 text-red-400">
      <span>* {{ error }}</span>
    </div>
    <div class="flex items-center flex-wrap gap-3 mb-4">
      <input
        class="rounded w-full"
        :placeholder="$t('sub-group')"
        type="text"
        v-model="name"
      />
    </div>
    <div class="mb-4" v-if="subgroup">
      <select-input
        :placeholder="$t('status')"
        :data="statusData"
        v-model="status"
        size="full"
      />
    </div>
    <div class="flex relative justify-end pt-2 gap-x-3">
      <button
        :disabled="loading"
        class="rounded text-white py-1.5 px-4 bg-WAORANGE-500 text-sm"
      >
        {{
          this.subgroup
            ? loading
              ? $t("editing")
              : $t("edit")
            : loading
            ? "Loading"
            : $t("add-sub-group")
        }}
      </button>
    </div>
  </form>
</template>

<script>
import httpClient from "../../services/httpClient";
import { mapMutations } from "vuex";

import SelectInput from "@/components/forms/SelectInput.vue";

export default {
  components: { SelectInput },
  data() {
    return {
      loading: false,
      error: null,
      name: this.subgroup ? this.subgroup.name : "",
      status: this.subgroup ? this.subgroup.status : "",
      statusData: [
        { id: 1, value: "Active" },
        { id: 2, value: "Disabled" },
      ],
    };
  },
  emits: ["onClose"],
  methods: {
    ...mapMutations("subgroups", [
      "addSubGroup",
      "removeSubGroup",
      "updateSubGroup",
    ]),
    async onSubmit() {
      if (this.error) this.error = null;
      if (!this.name) return (this.error = "Please fill in all fields");

      try {
        this.loading = true;

        if (this.subgroup) {
          const { data } = await httpClient.put(
            `/admin/subgroups/${this.subgroup._id}`,
            {
              name: this.name,
              status: this.status,
            }
          );

          this.updateSubGroup(data);
        } else {
          const { data } = await httpClient.post(
            `/admin/subgroups/group/${this.groupId}`,
            {
              name: this.name,
            }
          );

          this.addSubGroup(data);
        }

        this.loading = false;
        this.$emit("onClose");
      } catch (err) {
        this.loading = false;
        this.error =
          err?.response && err.response?.data?.error
            ? err.response?.data.error
            : err.error || err.message;
      }
    },
  },
  props: ["groupId", "subgroup"],
};
</script>

<style scoped>
input {
  border: none;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  font-size: 14px;
}

form {
  width: 80vw;
}

@media screen and (min-width: 640px) {
  form {
    width: 400px;
  }
}
</style>
