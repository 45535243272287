<template>
  <Loader v-if="groupLoading || userLoading" />
  <form v-else @submit.prevent="onSubmit">
    <div v-if="error" class="py-3 px-5 mb-3 rounded bg-red-50 text-red-400">
      <span>* {{ error }}</span>
    </div>
    <label class="inline-block mb-2 text-sm text-gray-500">{{
      $t("sub-group-name")
    }}</label>
    <SelectInput
      :data="getInputData('name')"
      :returnId="true"
      :filterMode="true"
      :placeholder="$t('select-sub-group')"
      size="full"
      v-model="groupId"
    />
    <div class="my-4">
      <div v-if="selectedSubGroup">
        <div class="mb-2" v-if="selectedSubGroup?.users.length > 0">
          <label class="inline-block mb-2 text-sm text-gray-500"
            >{{ $t("users-in") }} {{ selectedSubGroup.name }}</label
          >
          <div
            class="user relative py-2 mb-2 px-4 rounded flex justify-between"
            v-for="user in selectedSubGroup?.users"
            :key="user._id"
          >
            <h1 class="text-sm text-WADARKBLUE-500">
              {{ user.name }}
            </h1>
            <confirm-modal
              :show="showConfirm.status && showConfirm.id === user.id"
              @no="closeConfirmModal"
              @yes="confirmed(user.id)"
            ></confirm-modal>

            <button
              :disabled="deleting.status && deleting.id === user._id"
              type="button"
              @click="openConfirmModal(user.id)"
            >
              <img
                class="w-4"
                v-if="deleting.status && deleting?.id === user.id"
                src="/loader.svg"
                alt="loader"
              />
              <img v-else src="/trash.svg" alt="trash" />
            </button>
          </div>
        </div>
        <div class="mb-2" v-else>
          <p class="mb-2 text-sm text-gray-500">
            {{ $t("no-user-yet-in") }} {{ selectedSubGroup.name }}
          </p>
        </div>
      </div>
    </div>
    <label class="inline-block mb-2 text-sm text-gray-500">{{
      $t("user-name")
    }}</label>
    <SelectInput
      :data="getUsersInputData('name')"
      :returnId="true"
      :filterMode="true"
      :placeholder="$t('select-user')"
      size="full"
      v-model="userId"
    />
    <div class="flex items-center justify-end gap-3">
      <button
        :disabled="loading || deleting.status"
        type="submit"
        class="rounded text-white py-2 px-4 bg-WAORANGE-500 text-sm mt-4"
      >
        {{ loading ? $t("adding") : $t("assign-user") }}
      </button>
    </div>
  </form>
</template>

<script>
import SelectInput from "@/components/forms/SelectInput.vue";
import Loader from "@/components/Loader.vue";
import ConfirmModal from "@/components/ConfirmModal.vue";

import { mapActions, mapGetters, mapMutations } from "vuex";
import httpClient from "../../services/httpClient";

export default {
  components: { SelectInput, Loader, ConfirmModal },
  computed: {
    ...mapGetters("users", { userList: "list" }),
    ...mapGetters("users", { userLoading: "loading" }),
    ...mapGetters("users", { groupLoading: "loading" }),
    ...mapGetters("subgroups", ["list"]),
  },
  async created() {
    await this.getUsers();
  },
  data() {
    return {
      loading: false,
      deleting: { id: null, status: false },
      showConfirm: { id: null, status: false },
      error: null,
      groupId: "",
      userId: "",
      selectedSubGroup: null,
    };
  },
  methods: {
    ...mapActions("users", ["getUsers"]),
    ...mapMutations("subgroups", ["assignUser", "removeUserFromSubGroup"]),
    getInputData(name) {
      return this.list.map((group) => ({ id: group._id, value: group[name] }));
    },
    getUsersInputData(name) {
      return this.userList.map((user) => ({ id: user.id, value: user[name] }));
    },
    async onSubmit() {
      if (this.error) this.error = null;
      if (!this.groupId) return (this.error = "Please select group");
      if (!this.userId) return (this.error = "Please select user");

      const userIds = this.selectedSubGroup.users.map((user) => user.id);
      if (userIds.includes(this.userId))
        return (this.error = `The user is already in ${this.selectedSubGroup.name}`);

      try {
        this.loading = true;
        const { data } = await httpClient.post(
          `/admin/subgroups/user/${this.groupId}/${this.userId}`
        );

        this.loading = false;
        this.assignUser({ id: this.groupId, user: data });
        // this.$emit("onClose");
      } catch (err) {
        this.loading = false;
        this.error =
          err.response && err.response.data.error
            ? err.response.data.error
            : err.error || err.message;
      }
    },

    async removeUser(userId) {
      if (this.error) this.error = null;
      if (!this.groupId) return (this.error = "Please select sub group");

      try {
        this.deleting.status = true;
        this.deleting.id = userId;
        await httpClient.delete(
          `/admin/subgroups/user/${this.groupId}/${userId}`
        );

        this.deleting.status = false;
        this.deleting.id = null;
        this.removeUserFromSubGroup({ groupId: this.groupId, userId });
      } catch (err) {
        this.deleting.status = false;
        this.deleting.id = null;
        this.error =
          err.response && err.response.data.error
            ? err.response.data.error
            : err.error || err.message;
      }
    },

    confirmed(userId) {
      this.closeConfirmModal();
      this.removeUser(userId);
    },

    openConfirmModal(userId) {
      this.showConfirm.id = userId;
      this.showConfirm.status = true;
    },

    closeConfirmModal() {
      this.showConfirm.id = null;
      this.showConfirm.status = false;
    },
  },

  watch: {
    groupId(newValue) {
      const found = this.list.find((group) => group._id === newValue);
      if (found) this.selectedSubGroup = found;
      else this.selectedSubGroup = null;
    },
  },
};
</script>

<style scoped>
form {
  width: 80vw;
}

@media screen and (min-width: 640px) {
  form {
    width: 400px;
  }
}
</style>
