<template>
  <Modal :show="showModal" @onClose="closeModal">
    <template #header>{{ $t("add-sub-group") }}</template>
    <template #body
      ><sub-group-form
        @onClose="closeModal"
        :groupId="$route.params.id"
        :subgroup="selectedSubGroup"
    /></template>
  </Modal>

  <Modal :show="showUserModal" @onClose="closeUserModal">
    <template #header>{{ $t("assign-user") }}</template>
    <template #body
      ><assign-sub-user-form @onClose="closeUserModal"
    /></template>
  </Modal>

  <!-- show only if loading from server not from cache -->
  <Loader v-if="loading && list.length === 0" />
  <div v-else>
    <section class="max-w-screen-2xl mx-auto pt-6 px-2 sm:px-6 lg:px-8">
      <div class="flex flex-row items-center justify-start">
        <div class="flex items-center">
          <div class="mr-2">
            <img src="/icon-top-users.svg" class="h-4 w-4" />
          </div>
          <div>
            <h1 class="font-bold text-WADARKBLUE-500 text-base uppercase">
              {{ $t("sub-group") }}
            </h1>
          </div>
        </div>
      </div>
    </section>
    <section class="max-w-screen-2xl mx-auto px-2 py-6 sm:px-6 lg:px-8">
      <div class="flex items-center justify-between">
        <div class="flex items-center">
          <button
            v-if="isAdmin"
            @click="openModal"
            class="bg-WAORANGE-500 hover:bg-WAORANGE-400 mr-2 px-4 py-2 rounded-lg text-white text-xs uppercase"
          >
            + {{ $t("add-sub-group") }}
          </button>
          <button
            v-if="isAdmin"
            @click="openUserModal"
            class="border border-WAORANGE-500 hover:bg-white mr-2 px-4 py-2 rounded-lg text-xs uppercase"
          >
            {{ $t("assign-user") }}
          </button>
        </div>
        <sub-group-filter></sub-group-filter>
      </div>
    </section>
    <section class="lg:px-8 max-w-screen-2xl mx-auto px-2 sm:px-6">
      <sub-group-table-row></sub-group-table-row>
      <div v-if="error" class="py-3 px-5 mb-3 rounded bg-red-50 text-red-400">
        <span>* {{ error }}</span>
      </div>
      <div v-if="filteredSubGroups.length > 0">
        <Group
          v-for="subgroup in filteredSubGroups"
          :key="subgroup._id"
          :group="subgroup"
          :isSub="true"
          @edit="editSubGroup(subgroup)"
        />
      </div>
      <div v-else>
        <h1 class="text-sm text-center text-WADARKBLUE-500 my-10">
          {{ $t("no-data") }}
        </h1>
      </div>
    </section>
  </div>
</template>

<script>
import Group from "@/components/groups/Group.vue";
import SubGroupForm from "@/components/groups/SubGroupForm.vue";
import AssignSubUserForm from "@/components/groups/AssignSubUserForm.vue";
import SubGroupFilter from "@/components/groups/SubGroupFilter.vue";
import SubGroupTableRow from "@/components/groups/SubGroupTableRow.vue";
import Loader from "@/components/Loader.vue";
import Modal from "@/components/Modal.vue";

import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    Group,
    Loader,
    SubGroupForm,
    Modal,
    AssignSubUserForm,
    SubGroupFilter,
    SubGroupTableRow,
  },
  computed: {
    ...mapGetters("subgroups", [
      "loading",
      "error",
      "list",
      "filteredSubGroups",
    ]),
    ...mapGetters(["currentUser"]),
    isAdmin() {
      return (
        (this.currentUser && this.currentUser?.role?.group?.admin) ||
        this.currentUser?.isSuper
      );
    },
  },
  async created() {
    this.groupId = this.$route.params?.id;
    await this.getSubGroups(this.groupId);
  },
  data() {
    return {
      showModal: false,
      showUserModal: false,
      groupId: "",
      selectedSubGroup: null,
    };
  },
  methods: {
    ...mapActions("subgroups", ["getSubGroups"]),
    openModal() {
      this.showModal = true;
      this.selectedSubGroup = null;
    },
    closeModal() {
      this.showModal = false;
      this.selectedSubGroup = null;
    },
    openUserModal() {
      this.showUserModal = true;
    },
    closeUserModal() {
      this.showUserModal = false;
    },
    editSubGroup(subgroup) {
      this.selectedSubGroup = subgroup;
      this.showModal = true;
    },
  },
  watch: {
    groupId(newVal) {
      this.groupId = newVal;
    },
  },
};
</script>
